import React, { useEffect } from 'react';
import { Routes } from 'react-router-dom';
import Alert from '../components/Alert';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';
import { PrivateContent } from './styles';
import { routes, RoutesBuilder } from './routes';
import { Box, useTheme } from '@mui/material';

const Private = () => {
	const theme = useTheme();

	useEffect(() => {
		document.body.style.background = theme.palette.background.default;

		return () => {
			document.body.style.background = theme.palette.background.default;
		};
	}, [theme]);

	return (
		<>
			<Alert privateRoutes />
			<Header />
			<Sidebar />
			<PrivateContent>
				<Box>
					<Box>
						<React.Suspense fallback={<Loader />}>
							<Routes>{RoutesBuilder(routes)}</Routes>
						</React.Suspense>
					</Box>
				</Box>
			</PrivateContent>
		</>
	);
};

export default Private;
