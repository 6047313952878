import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { uiActions } from '../actions/ui';
import { operationsActions } from '../actions/operations';
import { localesActions } from '../actions/locales';
import { dashboardActions } from '../actions/dashboard';
import AffiliateService from '../../services/affiliates.service';
import { stringifyText } from '../../utils/text';
import { extendExpiration } from '../../utils/extendExpiration';
import { logout } from './auth';

function* getHelpPage({ payload }) {
	const { countries } = yield select(({ locales }) => locales);

	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getManagerData,
		payload,
		operationsActions.getManagerDataSuccessAction
	);
	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			payload,
			localesActions.getCountrySuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* getCommissionPlanPage({ payload }) {
	const { countries } = yield select(({ locales }) => locales);
	const { products, brands, sources } = yield select(
		({ dashboard }) => dashboard
	);
	const { costTypes } = yield select(({ operations }) => operations);

	yield put(operationsActions.getCommissionPlansSuccessAction([]));
	yield put(uiActions.startLoaderAction());
	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			payload,
			localesActions.getCountrySuccessAction
		);
	yield brands?.length <= 1 &&
		call(
			handleResponse,
			AffiliateService.getBrands,
			payload,
			dashboardActions.getBrandsSuccessAction
		);
	yield !products?.length &&
		call(
			handleResponse,
			AffiliateService.getProducts,
			payload,
			dashboardActions.getProductsSuccessAction
		);
	yield !sources?.length &&
		call(
			handleResponse,
			AffiliateService.getSources,
			payload,
			dashboardActions.getSourcesSuccessAction
		);
	yield !costTypes?.length &&
		call(
			handleResponse,
			AffiliateService.getCostTypes,
			payload,
			operationsActions.getCostTypesSuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* getSourcesCommission({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getCommissions,
		payload,
		operationsActions.getCommissionsSuccessAction
	);
	yield put(uiActions.endLoaderAction());
}
function* getTrackingLinks({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getTrackingLinks,
		payload,
		operationsActions.getTrackingLinksSuccessAction
	);
	yield put(uiActions.endLoaderAction());
}
function* getMacros({ payload }) {
	const { params } = yield select(({ operations }) => operations);

	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getMacros,
		payload,
		operationsActions.getMacrosSuccessAction
	);
	yield !params?.length &&
		call(
			handleResponse,
			AffiliateService.getParams,
			payload,
			operationsActions.getParamsSuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* getPostbacks({ payload }) {
	const { params } = yield select(({ operations }) => operations);

	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getPostbacks,
		payload,
		operationsActions.getPostbacksSuccessAction
	);
	yield call(
		handleResponse,
		AffiliateService.getMacros,
		payload,
		operationsActions.getMacrosSuccessAction
	);
	yield !params?.length &&
		call(
			handleResponse,
			AffiliateService.getParams,
			payload,
			operationsActions.getParamsSuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* getCommissionPlans({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield put(operationsActions.getCommissionPlansSuccessAction([]));
	yield call(
		handleResponse,
		AffiliateService.getCommissionPlans,
		payload,
		operationsActions.getCommissionPlansSuccessAction
	);
	yield put(uiActions.endLoaderAction());
}
function* getCompliancePage({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getCompliance,
		payload,
		operationsActions.getCompliancePageSuccessAction
	);
	yield put(uiActions.endLoaderAction());
}
function* getPostbackPage({ payload }) {
	const { brands, sources } = yield select(({ dashboard }) => dashboard);
	const { events } = yield select(({ operations }) => operations);

	yield put(uiActions.startLoaderAction());
	yield !brands?.length <= 1 &&
		call(
			handleResponse,
			AffiliateService.getBrands,
			payload,
			dashboardActions.getBrandsSuccessAction
		);
	yield !sources?.length &&
		call(
			handleResponse,
			AffiliateService.getSources,
			payload,
			dashboardActions.getSourcesSuccessAction
		);
	yield !events?.length &&
		call(
			handleResponse,
			AffiliateService.getEvents,
			payload,
			operationsActions.getEventsSuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* sendContactUsForm({ payload }) {
	yield put(uiActions.startLoaderAction());
	const contactUsSent = yield call(
		handleResponse,
		AffiliateService.sendContactUsForm,
		payload
	);
	yield put(uiActions.endLoaderAction());
	yield contactUsSent?.data &&
		put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Your message was successfully sent',
			})
		);
	yield contactUsSent?.data &&
		put(operationsActions.setFormSentStatusAction(true));
}
function* signDocument({ payload }) {
	yield put(uiActions.startLoaderAction());
	const document = yield call(handleResponse, AffiliateService.signDocument, payload);
	yield put(uiActions.endLoaderAction());
	yield !document.error &&
		put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Please check your mailbox',
			})
		);
}
function* createBenefeciary({ payload }) {
	yield put(uiActions.startLoaderAction());
	// yield call(
	// 	handleResponse,
	// 	AffiliateService.createBenefeciary,
	// 	payload,
	// 	operationsActions.createBenefeciarySuccessAction,
	// );
	yield put(operationsActions.createBenefeciarySuccessAction());
	yield put(uiActions.endLoaderAction());
}
function* updateMacros({ payload }) {
	yield put(uiActions.startLoaderAction());
	const macros = yield call(
		handleResponse,
		AffiliateService.updateMacros,
		payload,
		operationsActions.getMacrosSuccessAction
	);
	yield put(uiActions.endLoaderAction());
	yield macros?.data &&
		put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Change applied',
			})
		);
	yield macros?.data && put(uiActions.toggleModalOpenAction(false));
}
function* updatePostback({ payload }) {
	yield put(uiActions.startLoaderAction());
	const postback = yield call(
		handleResponse,
		AffiliateService.updatePostback,
		payload,
		operationsActions.updatePostbackSuccessAction
	);
	yield put(uiActions.endLoaderAction());
	yield postback?.data && put(uiActions.toggleModalOpenAction(false));
	yield postback?.data &&
		put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Postback updated',
			})
		);
}
function* createPostback({ payload }) {
	yield put(uiActions.startLoaderAction());
	const postback = yield call(
		handleResponse,
		AffiliateService.createPostback,
		payload,
		operationsActions.createPostbackSuccessAction
	);
	yield put(uiActions.endLoaderAction());
	yield postback?.data && put(uiActions.toggleModalOpenAction(false))
	yield postback?.data && put(
		uiActions.setAlertAction({
			show: true,
			severity: 'success',
			body: 'Postback was successfully created',
		})
	);
}
function* handleResponse(fetchFunc, payload, onSuccess) {
	try {
		const response = yield call(fetchFunc, payload);
		yield !!response?.data
			? handleSuccess({ response, onSuccess })
			: call(handleError, response);
		return response;
	} catch (error) {
		yield handleError(error);
	}
}
function* handleSuccess({ response, onSuccess }) {
	yield onSuccess && put(onSuccess(response?.data));
	yield call(extendExpiration);
}
function* handleError(e) {
	const error = e?.error || e;
	const errorText = stringifyText(error);

	if (errorText.includes('ERROR_NOT_AUTHORIZED')) {
		yield call(logout);
	} else {
		yield put(operationsActions.setErrorAction());
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: errorText,
			})
		);
	}
	yield put(uiActions.endLoaderAction());
}

function* watchOperations() {
	yield takeLatest(operationsActions.getHelpPageAction.type, getHelpPage);
	yield takeLatest(
		operationsActions.getCommissionPlanPageAction.type,
		getCommissionPlanPage
	);
	yield takeLatest(
		operationsActions.getSourceCommissionAction.type,
		getSourcesCommission
	);
	yield takeLatest(
		operationsActions.getTrackingLinksAction.type,
		getTrackingLinks
	);
	yield takeLatest(operationsActions.getMacrosAction.type, getMacros);
	yield takeLatest(operationsActions.getPostbacksAction.type, getPostbacks);
	yield takeLatest(
		operationsActions.getCommissionPlansAction.type,
		getCommissionPlans
	);
	yield takeLatest(
		operationsActions.getCompliancePageAction.type,
		getCompliancePage
	);
	yield takeLatest(
		operationsActions.getPostbackPageAction.type,
		getPostbackPage
	);
	yield takeLatest(
		operationsActions.sendContactUsFormAction.type,
		sendContactUsForm
	);
	yield takeLatest(operationsActions.signDocumentAction.type, signDocument);
	yield takeLatest(operationsActions.createBenefeciaryAction.type, createBenefeciary);
	yield takeLatest(operationsActions.updateMacrosAction.type, updateMacros);
	yield takeLatest(operationsActions.updatePostbackAction.type, updatePostback);
	yield takeLatest(operationsActions.createPostbackAction.type, createPostback);
}
function* rootOperations() {
	yield fork(watchOperations);
}

export { rootOperations };
