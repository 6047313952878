import Cookies from 'js-cookie';

export const extendExpiration = () => {
	const initialExpireTime =
		Number(localStorage.getItem('initialExpireTime')) || 60000; // 60000 for the backup
	const SID = Cookies.get('SID');
	localStorage.setItem('expires', Date.now() + initialExpireTime);
	SID &&
		Cookies.set('SID', SID, {
			expires: new Date(Date.now() + initialExpireTime),
		});
};
