import { styled, Box } from '@mui/material';

// export const PrivateWrapper = styled(Box)(
// 	({ theme }) => `
//     flex: 1 1 auto;
//     display: flex;
//     height: 100%;
//     background-color: ${theme.palette.background.default};
    
//     @media (min-width: ${theme.breakpoints.values.lg}px) {
//       padding-left: ${theme.sidebar.width};
//     }

//     .customScroll {
//       &::-webkit-scrollbar {
//         display: block;
//         height: 5px;
//         padding: 1px;
//       }
//       &::-webkit-scrollbar-track {
//         background: transparent;
//       }
//       &::-webkit-scrollbar-thumb {
//         background: ${theme.colors.secondary.light};
//         border-radius: 8px;
//       }
//     }
//   `
// );
export const PublicWrapper = styled(Box)(
	({ theme }) => `
    flex: 1 1 auto;
    display: flex;
    height: 100%;
    background-color: ${theme.palette.background.default};
  `
);
export const PrivateContent = styled(Box)(
	({ theme }) => `
    padding-top: ${theme.header.height};
    position: relative;
    z-index: 5;
    flex: 1;
    display: flex;
    box-sizing: border-box;
    background-color: ${theme.palette.background.default};

    > div {
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;

      > div {
        flex-grow: 1;
        -webkit-box-flex: 1;
        box-sizing: border-box;
      }
    }

    @media (min-width: ${theme.breakpoints.values.lg}px) {
      padding-left: ${theme.sidebar.width};
    }
  `
);
export const PublicContent = styled(Box)(
	({ theme }) => `
    height: 100vh;
    position: relative;
    flex: 1 1 auto;
    overflow: auto;
  `
);
