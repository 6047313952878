import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { uiActions } from '../actions/ui';
import { dashboardActions } from '../actions/dashboard';
import AffiliateService from '../../services/affiliates.service';
import { localesActions } from '../actions/locales';
import { walletActions } from '../actions/wallet';
import { stringifyText } from '../../utils/text';
import { extendExpiration } from '../../utils/extendExpiration';
import { logout } from './auth';

function* getDashboardData({ payload }) {
	const { brands, sources } = yield select(({ dashboard }) => dashboard);
	const { advBrands } = yield select(({ wallet }) => wallet);
	const { countries, currencies, benCountries, benCurrencies } = yield select(({ locales }) => locales);

	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getDashboardData,
		payload,
		dashboardActions.getDashboardDataSuccessAction
	);
	yield !brands?.length &&
		call(
			handleResponse,
			AffiliateService.getBrands,
			payload,
			dashboardActions.getBrandsSuccessAction
		);
	yield !advBrands?.length &&
		call(
			handleResponse,
			AffiliateService.getWalletBrands,
			payload,
			walletActions.getWalletBrandsSuccessAction
		);
	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			null,
			localesActions.getCountrySuccessAction
		);
	yield !currencies?.length &&
		call(
			handleResponse,
			AffiliateService.getCurrency,
			null,
			localesActions.getCurrencySuccessAction
		);
	yield !benCountries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			payload,
			localesActions.getBenCountrySuccessAction
		);
	yield !benCurrencies?.length &&
		call(
			handleResponse,
			AffiliateService.getCurrency,
			payload,
			localesActions.getBenCurrencySuccessAction
		);
	yield !sources?.length &&
		call(
			handleResponse,
			AffiliateService.getSources,
			payload,
			dashboardActions.getSourcesSuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* getWalletData ({ payload }) {
	const { brands, sources } = yield select(({ dashboard }) => dashboard);
	const { advBrands } = yield select(({ wallet }) => wallet);
	const { countries, currencies } = yield select(({ locales }) => locales);

	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getWalletData,
		payload,
		dashboardActions.getWalletDataSuccessAction
	);
	yield !brands?.length &&
		call(
			handleResponse,
			AffiliateService.getBrands,
			payload,
			dashboardActions.getBrandsSuccessAction
		);
	yield !advBrands?.length &&
		call(
			handleResponse,
			AffiliateService.getWalletBrands,
			payload,
			walletActions.getWalletBrandsSuccessAction
		);
	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			payload,
			localesActions.getCountrySuccessAction
		);
	yield !currencies?.length &&
		call(
			handleResponse,
			AffiliateService.getCurrency,
			payload,
			localesActions.getCurrencySuccessAction
		);
	yield !sources?.length &&
		call(
			handleResponse,
			AffiliateService.getSources,
			payload,
			dashboardActions.getSourcesSuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* getDashboardAnalytic({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.getBalanceAnalytic,
		payload,
		dashboardActions.getBalanceAnalyticSuccessAction
	);
	yield put(uiActions.endLoaderAction());
}
function* getSources({ payload }) {
	const { sources } = yield select(({ dashboard }) => dashboard);

	yield !sources?.length &&
		call(
			handleResponse,
			AffiliateService.getSources,
			payload,
			dashboardActions.getSourcesSuccessAction
		);
}
function* handleResponse(fetchFunc, payload, onSuccess) {
	try {
		const response = yield call(fetchFunc, payload);
		yield !!response?.data
			? handleSuccess({ response, onSuccess })
			: call(handleError, response);
		return response;
	} catch (error) {
		yield handleError(error);
	}
}
function* handleSuccess({ response, onSuccess }) {
	yield onSuccess && put(onSuccess(response?.data));
	yield call(extendExpiration);
}
function* handleError(e) {
	const error = e?.error || e;
	const errorText = stringifyText(error);

	if (errorText.includes('ERROR_NOT_AUTHORIZED')) {
		yield call(logout);
	} else {
		yield put(dashboardActions.setErrorAction());
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: errorText,
			})
		);
	}
	yield put(uiActions.endLoaderAction());
}

function* watchDashboard() {
	yield takeLatest(
		dashboardActions.getDashboardDataAction.type,
		getDashboardData
	);
	yield takeLatest(
		dashboardActions.getWalletDataAction.type,
		getWalletData
	);
	yield takeLatest(
		dashboardActions.getDashboardAnalyticAction.type,
		getDashboardAnalytic
	);
	yield takeLatest(dashboardActions.getSourcesAction.type, getSources);
}
function* rootDashboard() {
	yield fork(watchDashboard);
}

export { rootDashboard };
