import { fork, call, put, takeLatest, select } from 'redux-saga/effects';
import { userActions } from '../actions/user';
import AffiliateService from '../../services/affiliates.service';
import { stringifyText } from '../../utils/text';
import { uiActions } from '../actions/ui';
import { localesActions } from '../actions/locales';
import { extendExpiration } from '../../utils/extendExpiration';
import { logout } from './auth';

function* getUserData({ payload }) {
	const { countries, currencies } = yield select(({ locales }) => locales);

	yield put(uiActions.startLoaderAction());
	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			null,
			localesActions.getCountrySuccessAction
		);
	yield !currencies?.length &&
		call(
			handleResponse,
			AffiliateService.getCurrency,
			null,
			localesActions.getCurrencySuccessAction
		);
	yield call(
		handleResponse,
		AffiliateService.getUserData,
		payload,
		userActions.setUserInfoAction
	);
	yield put(uiActions.endLoaderAction());
}
function* updateUserData({ payload }) {
	yield put(uiActions.startLoaderAction());
	yield call(
		handleResponse,
		AffiliateService.updateAffiliate,
		payload,
		userActions.setUserInfoAction
	);
	yield put(uiActions.endLoaderAction());
}
function* updateProfile({ payload }) {
	yield put(uiActions.startLoaderAction());
	const data = yield call(
		handleResponse,
		AffiliateService.updateProfile,
		payload,
		userActions.setUserInfoAction
	);
	yield put(uiActions.endLoaderAction());
	yield data?.data &&
		put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Profile sucessfully updated',
			})
		);
}
function* updatePreference({ payload }) {
	yield put(uiActions.startLoaderAction());
	const data = yield call(
		handleResponse,
		AffiliateService.updateNotificationsPreferences,
		payload
	);
	yield data?.data === 'OK' &&
		put(userActions.updatePreferencesSuccessAction(payload?.data));
	yield put(uiActions.endLoaderAction());
}
function* changePassword({ payload }) {
	yield put(uiActions.startLoaderAction());
	const data = yield call(
		handleResponse,
		AffiliateService.changePassword,
		payload,
		() => userActions.setFormSentStatusAction(true)
	);
	yield put(uiActions.endLoaderAction());
	yield data.data === 'OK' &&
		put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Password was successfully changed',
			})
		);
}
function* createBeneficiary({ payload }) {
	yield put(uiActions.startLoaderAction());
	const beneficiary = yield call(
		handleResponse,
		AffiliateService.createBeneficiary,
		payload,
		userActions.createBeneficiarySuccessAction
	);
	yield put(uiActions.endLoaderAction());
	
	if (beneficiary?.data?.error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: stringifyText(beneficiary?.data?.error),
			})
		);
	} else {
		yield put(uiActions.toggleModalOpenAction(false));
	}
}
function* getNotificationsPreferences({ payload }) {
	const data = yield call(
		handleResponse,
		AffiliateService.getNotificationsPreferences,
		payload,
		userActions.getNotificationsPreferencesSuccessAction
	);
	yield !data.error && put(uiActions.toggleNotificationsAction(true));
}
function* acceptGuidelines({ payload }) {
	yield put(uiActions.startLoaderAction());
	const data = yield call(
		handleResponse,
		AffiliateService.acceptGuidelines,
		payload,
		userActions.acceptGuidelinesSuccessAction
	);
	yield put(uiActions.endLoaderAction());

	if (data?.data === 'OK') {
		yield put(uiActions.toggleModalOpenAction(false));
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'success',
				body: 'Guidelines accepted',
			})
		);
	}
}
function* handleResponse(fetchFunc, payload, onSuccess) {
	try {
		const response = yield call(fetchFunc, payload);
		yield !!response?.data
			? handleSuccess({ response, onSuccess })
			: call(handleError, response);
		return response;
	} catch (error) {
		yield handleError(error);
	}
}
function* handleSuccess({ response, onSuccess }) {
	yield onSuccess && put(onSuccess(response?.data));
	yield call(extendExpiration);
}
function* handleError(e) {
	const error = e?.error || e;
	const errorText = stringifyText(error);

	if (errorText.includes('ERROR_NOT_AUTHORIZED')) {
		yield call(logout);
	} else {
		yield put(userActions.setErrorAction());
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: errorText,
			})
		);
	}
	yield put(uiActions.endLoaderAction());
}

function* watchUser() {
	yield takeLatest(userActions.getUserDataAction.type, getUserData);
	yield takeLatest(userActions.updateUserDataAction.type, updateUserData);
	yield takeLatest(userActions.updateProfileAction.type, updateProfile);
	yield takeLatest(
		userActions.createBeneficiaryAction.type,
		createBeneficiary
	);
	yield takeLatest(userActions.updatePreferenceAction.type, updatePreference);
	yield takeLatest(userActions.changePasswordAction.type, changePassword);
	yield takeLatest(
		userActions.getNotificationsPreferencesAction.type,
		getNotificationsPreferences
	);
	yield takeLatest(userActions.acceptGuidelinesAction.type, acceptGuidelines);
}

function* rootUser() {
	yield fork(watchUser);
}

export { rootUser };
