import React from 'react';
import { Route } from 'react-router-dom';
import RequireAuth from './RequireAuth';
import NotRequireAuth from './NotRequireAuth';

const lazyRetry = function (componentImport) {
	return new Promise((resolve, reject) => {
		const hasRefreshed = JSON.parse(
			window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
		);
		componentImport()
			.then((component) => {
				window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
				resolve(component);
			})
			.catch((error) => {
				if (!hasRefreshed) {
					window.sessionStorage.setItem(
						'retry-lazy-refreshed',
						'true'
					);
					return window.location.reload();
				}
				reject(error);
			});
	});
};

// public
const Signin = React.lazy(() =>
	lazyRetry(() => import('../components/Login/Signin'))
);
const Signup = React.lazy(() =>
	lazyRetry(() => import('../components/Login/Signup'))
);
const LostPassword = React.lazy(() =>
	lazyRetry(() => import('../components/Login/LostPassword'))
);
const FirebaseComponentSwitch = React.lazy(() =>
	lazyRetry(() => import('../components/FirebaseComponentSwitch'))
);
const LoginAsAffiliate = React.lazy(() =>
	lazyRetry(() => import('../screens/LoginAsAffiliate'))
);

// private
const Dashboard = React.lazy(() =>
	lazyRetry(() => import('../screens/Dashboard'))
);
const StatsReport = React.lazy(() =>
	lazyRetry(() => import('../screens/Reports/StatsReport'))
);
const Wallet = React.lazy(() => lazyRetry(() => import('../screens/Wallet')));
const AccountProfile = React.lazy(() =>
	lazyRetry(() => import('../screens/AccountProfile'))
);
const MarketingTools = React.lazy(() =>
	lazyRetry(() => import('../screens/MarketingTools'))
);
const Postback = React.lazy(() =>
	lazyRetry(() => import('../screens/Postback'))
);
const CommissionPlan = React.lazy(() =>
	lazyRetry(() => import('../screens/CommissionPlan'))
);
const Compliance = React.lazy(() =>
	lazyRetry(() => import('../screens/Compliance'))
);
const Help = React.lazy(() => lazyRetry(() => import('../screens/Help')));

const routes = [
	{
		path: '/',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
	{
		path: '/dashboard',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
	{
		path: '/report/aff_traffic',
		element: (
			<RequireAuth>
				<StatsReport />
			</RequireAuth>
		),
	},
	{
		path: '/user_profile',
		element: (
			<RequireAuth>
				<AccountProfile />
			</RequireAuth>
		),
	},
	{
		path: '/wallet',
		element: (
			<RequireAuth>
				<Wallet />
			</RequireAuth>
		),
	},
	{
		path: '/help',
		element: (
			<RequireAuth>
				<Help />
			</RequireAuth>
		),
	},
	{
		path: '/postback',
		element: (
			<RequireAuth>
				<Postback />
			</RequireAuth>
		),
	},
	{
		path: '/commission_plan',
		element: (
			<RequireAuth>
				<CommissionPlan />
			</RequireAuth>
		),
	},
	{
		path: '/compliance',
		element: (
			<RequireAuth>
				<Compliance />
			</RequireAuth>
		),
	},
	{
		path: '/tools',
		element: (
			<RequireAuth>
				<MarketingTools />
			</RequireAuth>
		),
	},
	{
		path: '/signin',
		element: (
			<NotRequireAuth>
				<Signin />
			</NotRequireAuth>
		),
	},
	{
		path: '/signin/:token',
		element: (
			<NotRequireAuth>
				<Signin />
			</NotRequireAuth>
		),
	},
	{
		path: '/signup',
		element: (
			<NotRequireAuth>
				<Signup />
			</NotRequireAuth>
		),
	},
	{
		path: '/lost_password',
		element: (
			<NotRequireAuth>
				<LostPassword />
			</NotRequireAuth>
		),
	},
	{
		path: '/nav',
		element: (
			<NotRequireAuth>
				<FirebaseComponentSwitch />
			</NotRequireAuth>
		),
	},
	{
		path: '/login_as/:token',
		element: (
			<NotRequireAuth>
				<LoginAsAffiliate />
			</NotRequireAuth>
		),
	},
	{
		path: '*',
		element: (
			<RequireAuth>
				<Dashboard />
			</RequireAuth>
		),
	},
];

const RoutesBuilder = (_routes) => {
	return _routes?.map((route, i) => {
		return <Route key={i} path={route.path} element={route.element} />;
	});
};
export { routes, RoutesBuilder };
