import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { uiActions } from '../actions/ui';
import { localesActions } from '../actions/locales';
import AffiliateService from '../../services/affiliates.service';
import { stringifyText } from '../../utils/text';
import { extendExpiration } from '../../utils/extendExpiration';
import { logout } from './auth';

function* getSignupPageLocales({ payload }) {
	const { countries, currencies } = yield select(({ locales }) => locales);

	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			payload,
			localesActions.getCountrySuccessAction
		);
	yield !currencies?.length &&
		call(
			handleResponse,
			AffiliateService.getCurrency,
			payload,
			localesActions.getCurrencySuccessAction
		);
}
function* getCountry({ payload }) {
	const { countries } = yield select(({ locales }) => locales);

	yield put(uiActions.startLoaderAction());
	yield !countries?.length &&
		call(
			handleResponse,
			AffiliateService.getCountry,
			payload,
			localesActions.getCountrySuccessAction
		);
	yield put(uiActions.endLoaderAction());
}
function* getCountryStates({ payload }) {
	yield call(
		handleResponse,
		AffiliateService.getCountryStates,
		payload,
		localesActions.getCountryStatesSuccessAction
	);
}
function* handleResponse(fetchFunc, payload, onSuccess) {
	try {
		const response = yield call(fetchFunc, payload);
		yield !!response?.data
			? handleSuccess({ response, onSuccess })
			: call(handleError, response);
		return response;
	} catch (error) {
		yield handleError(error);
	}
}
function* handleSuccess({ response, onSuccess }) {
	yield onSuccess && put(onSuccess(response?.data));
	yield call(extendExpiration);
}
function* handleError(e) {
	const error = e?.error || e;
	const errorText = stringifyText(error);

	if (errorText.includes('ERROR_NOT_AUTHORIZED')) {
		yield call(logout);
	} else {
		yield put(localesActions.setErrorAction());
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: errorText,
			})
		);
	}
	yield put(uiActions.endLoaderAction());
}

function* watchLocales() {
	yield takeLatest(
		localesActions.getSignUpLocalesAction.type,
		getSignupPageLocales
	);
	yield takeLatest(localesActions.getCountryAction.type, getCountry);
	yield takeLatest(
		localesActions.getCountryStateAction.type,
		getCountryStates
	);
}
function* rootLocales() {
	yield fork(watchLocales);
}

export { rootLocales };
