import { call, fork, put, takeLatest } from 'redux-saga/effects';
import Cookies from 'js-cookie';
import { uiActions } from '../actions/ui';
import { authActions } from '../actions/auth';
import { userActions } from '../actions/user';
import { dashboardActions } from '../actions/dashboard';
import { operationsActions } from '../actions/operations';
import history from '../../navigator/history';
import AffiliateService from '../../services/affiliates.service';

function* login({ payload }) {
	const caller = payload.data
		? AffiliateService.login
		: AffiliateService.loginAs;
	const payloadData = payload.data || payload.token;

	try {
		yield put(uiActions.startLoaderAction());
		const { data, error } = yield call(caller, payloadData);

		if (!error) {
			const currentTheme = localStorage.getItem('appTheme');
			yield put(dashboardActions.clearDashboardDataAction());
			yield put(operationsActions.clearOperationsDataAction());
			yield put(userActions.setUserInfoAction(data?.info));
			yield put(
				authActions.signinSuccessAction({
					xSID: data?.SID,
					userInfo: data?.info,
				})
			);
			const notificationsData = yield data?.SID &&
				call(AffiliateService.getNotificationsPreferences, {
					xSID: data.SID,
				});
			yield notificationsData?.data &&
				put(
					userActions.getNotificationsPreferencesSuccessAction(
						notificationsData?.data
					)
				);
			data.SID && Cookies.set('SID', data.SID, { expires: new Date(Date.now() + data.expires * 1000) });
			data.expires && localStorage.setItem('initialExpireTime', data.expires * 1000);
			data.expires && localStorage.setItem(
				'expires',
				Date.now() + Number(data.expires) * 1000
			);
			data.info && localStorage.setItem('userInfo', JSON.stringify(data.info));
			notificationsData?.data &&
				localStorage.setItem(
					'notifications',
					JSON.stringify(notificationsData?.data)
				);
			currentTheme
				? localStorage.setItem('appTheme', currentTheme)
				: localStorage.setItem('appTheme', 'GreyGooseTheme');
			localStorage.removeItem('tmpl_pending');
			yield !data.info.type && put(uiActions.setNavPathAction('/signup'));
		} else if (error?.includes('ERROR_EMAIL_NOT_VERIFIED')) {
			// yield put(authActions.setSIDAction(data?.SID));
			yield put(authActions.setNotVerifiedAction());
		} else if (error?.includes('ERROR_NOT_APPROVED')) {
			yield put(authActions.setNotApprovedAction());
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: payload.token ? 'Login is not valid' : error,
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: error,
			})
		);
		yield put(authActions.signinFailureAction());
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}
function* signup({ payload }) {
	try {
		const fetcher = payload.xSID
			? AffiliateService.updateAffiliate
			: AffiliateService.signup;
		yield put(uiActions.startLoaderAction());
		const response = yield call(fetcher, payload);
		yield response?.data?.info && put(userActions.setUserInfoAction(response?.data?.info));
		response?.data?.info && localStorage.setItem('userInfo', JSON.stringify(response?.data?.info));
		if (!response.error) {
			yield put(authActions.setRegistrationSuccess());
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: response?.error,
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: error,
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}
function* resendLink({ payload }) {
	yield put(uiActions.startLoaderAction());
	const response = yield call(AffiliateService.resendLink, payload);

	if (response?.data === 'OK') {
		yield put(authActions.setLinkSentAction());
	} else {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: response?.error,
			})
		);
	}
	yield put(uiActions.endLoaderAction());
}
function* forgotPassword({ payload }) {
	try {
		yield put(uiActions.startLoaderAction());
		const response = yield call(AffiliateService.forgotPassword, payload);
		if (!response?.error) {
			yield put(authActions.emailReceivedAction());
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: response?.error,
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: error,
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}
function* resetPassword({ payload }) {
	try {
		yield put(uiActions.startLoaderAction());
		const response = yield call(AffiliateService.resetPassword, payload);
		if (response?.data === 'OK') {
			yield put(authActions.resetPasswordSuccessAction());
		} else {
			yield put(
				uiActions.setAlertAction({
					show: true,
					severity: 'error',
					body: response?.error,
				})
			);
		}
	} catch (error) {
		yield put(
			uiActions.setAlertAction({
				show: true,
				severity: 'error',
				body: error,
			})
		);
	} finally {
		yield put(uiActions.endLoaderAction());
	}
}
function* isLoggedIn() {
	try {
		let xSID = Cookies.get('SID');
		let expires = localStorage.getItem('expires');
		let userInfo = localStorage.getItem('userInfo');
		let appTheme = localStorage.getItem('appTheme') || 'GreyGooseTheme';
		let notifications = localStorage.getItem('notifications');

		if (
			xSID &&
			Date.now() < expires &&
			!history?.location?.pathname.includes('login_as')
		) {
			yield put(userActions.setUserInfoAction(JSON.parse(userInfo)));
			yield put(
				authActions.signinSuccessAction({
					xSID,
					userInfo: JSON.parse(userInfo),
				})
			);
			yield JSON.parse(notifications)?.length &&
				put(
					userActions.getNotificationsPreferencesSuccessAction(
						JSON.parse(notifications)
					)
				);
		} else {
			yield call(logout);
		}
		yield put(uiActions.setThemeAction(appTheme));
		localStorage.setItem('appTheme', appTheme);
	} catch (error) {
		yield call(logout);
	}
}
function* prepareLoginAsAffiliate({ payload }) {
	yield payload.xSID && call(logout, { payload });
	yield call(login, { payload });
}

export function* logout() {
	const xSID = Cookies.get('SID');
	yield call(AffiliateService.logout, { xSID });
	Cookies.remove('SID');
	localStorage.removeItem('userInfo');
	localStorage.removeItem('expires');
	localStorage.removeItem('initialExpireTime');
	localStorage.removeItem('notifications');
	localStorage.removeItem('sort_config');
	localStorage.removeItem('show_fields_config');
	localStorage.removeItem('filters_config');
	localStorage.removeItem('tmpl_pending');
	localStorage.removeItem('date_config_date');
	localStorage.removeItem('open_filter_config');
	localStorage.removeItem('open_output_config');
	yield put(authActions.logoutSuccessAction());
}

function* watchAuth() {
	yield takeLatest(authActions.signinAction.type, login);
	yield takeLatest(authActions.signupAction.type, signup);
	yield takeLatest(authActions.resendLinkAction.type, resendLink);
	yield takeLatest(authActions.forgotPasswordAction.type, forgotPassword);
	yield takeLatest(authActions.resetPasswordAction.type, resetPassword);
	yield takeLatest(authActions.isLoggedInAction.type, isLoggedIn);
	yield takeLatest(
		authActions.prepareLoginAsAffiliateAction.type,
		prepareLoginAsAffiliate
	);
	yield takeLatest(authActions.logoutAction.type, logout);
}

function* rootAuth() {
	yield fork(watchAuth);
}

export { rootAuth };
