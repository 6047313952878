import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from '../saga/rootSaga';
import uiReducer from '../reducers/ui';
import authReducer from '../reducers/auth';
import userReducer from '../reducers/user';
import sourcesReducer from '../reducers/sources';
import localeReducer from '../reducers/locales';
import dashboardReducer from '../reducers/dashboard';
import walletReducer from '../reducers/wallet';
import operationsReducer from '../reducers/operations';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === 'development') {
	const loggerMiddleware = createLogger();
	middlewares.push(loggerMiddleware);
}

export default function configureAppStore(preloadedState) {
	const store = configureStore({
		reducer: {
			ui: uiReducer,
			auth: authReducer,
			user: userReducer,
			sources: sourcesReducer,
			locales: localeReducer,
			dashboard: dashboardReducer,
			wallet: walletReducer,
			operations: operationsReducer,
		},
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: {
					ignoredPaths: ['operations.affRegulations'],
					ignoredActionPaths: ['meta.arg', 'payload.timestamp', 'payload.body'],
				},
			}).concat(middlewares),
		devTools: process.env.NODE_ENV !== 'production',
		preloadedState,
	});

	sagaMiddleware.run(rootSaga);

	return store;
}
